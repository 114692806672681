<template>
  <div v-if="amReady">

    <div v-if="!profileChanged">
      <el-row>
        <div class="mb-5 border-bottom">
          <h3>{{ getFormTitle() }}</h3>
        </div>

        <el-col :class="$langConfig.cssClass" :span="(!editMode)? 24 : 24" class="mb-3">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"

              :field_span="19"
              :label_span="24"
              field_mark="required"
              label="username"
              name="username"
              placeholder="GreenLeaf"
              rules="required"></dg-text-field>

          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="19"
              :label_span="24"
              field_mark="required"
              label="display_name"
              name="display_name"
              placeholder="Leaf That Never Dies"
              rules="required"></dg-text-field>

          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"

              :field_span="19"
              :label_span="24"
              :options="profilesOptionsList"
              :translateLabels="false"
              @amUpdated="profileUpdated"
              field_mark="required"
              label="profile"
              name="user_profile_id"
              rules="required"
              v-if="!isForCurrentActiveUser"></dg-select-field>

          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="19"
              :label_span="24"
              field_mark="required"
              htmlautocomplete="new-password"
              label="email"
              name="email"
              placeholder="your@email.com"
              rules="required"></dg-text-field>

          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="19"
              :label_span="24"
              field_mark="required"
              htmlautocomplete="new-password"
              label="password"
              name="password"
              placeholder="Secret"
              rules="required"
              type="password"
              v-if="showPasswordField"></dg-text-field>
        </el-col>
      </el-row>

      <el-row v-if="selectedProfileId == 6">
        <h6 class="border-bottom mb-3 font-weight-bold pb-2">Customer Profile Fields</h6>
        <el-col :class="$langConfig.cssClass" :span="(!editMode)? 24 : 24">
          <dg-label-field :translate="false" label="Using Private Rate"></dg-label-field>
          <el-switch v-model="dataModel.usingPrivateRate"></el-switch>
          <dg-label-field :translate="false" class="ml-4" label="LCR Feature"></dg-label-field>
          <el-switch v-model="dataModel.customerLCRFeature"></el-switch>
          <!--<div v-if="dataModel.usingPrivateRate" class="mt-2 ml-2">
            <small class="text-secondary font-italic">Customer Private Rate Document Should have  Working Destinations File fields with edited rates.</small>
            <dg-file-upload
                :allowed-ext="['xlsx','xls']"
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="24"
                :file-size-limit="12"
                :label_span="24"
                field_mark="required"
                label="rates_document"
                name="rate_document"
                rules="required"></dg-file-upload>
          </div>-->
        </el-col>
      </el-row>

      <div v-if="selectedProfileId == 5">
        <h6 class="border-bottom mb-3 font-weight-bold pb-2">Reseller Profile Fields</h6>
        <el-row>
          <el-col :class="$langConfig.cssClass" :span="(!editMode)? 6 : 6">
            <dg-text-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="19"
                :label_span="24"
                :translateLabel="false"
                field_mark="required"
                label="VOS Link"
                name="vos_link"
                placeholder="https://vos-link-to-recharge.com"
                rules=""></dg-text-field>
          </el-col>
          <el-col :class="$langConfig.cssClass" :span="(!editMode)? 6 : 6">
            <dg-text-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :defaultValue="12"
                :field_span="19"
                :label_span="24"
                :translateLabel="false"
                field_mark="required"
                label="percent (%)"
                name="system_profit_percent"
                placeholder="12"
                rules="required"></dg-text-field>
          </el-col>
        </el-row>

      </div>

      <div v-if="selectedProfileId == 7">
        <h6 class="border-bottom mb-3 font-weight-bold pb-2">MRT Profile Fields</h6>
        <el-row>
          <el-col :class="$langConfig.cssClass" :span="(!editMode)? 6 : 6">
            <dg-text-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :defaultValue="12"
                :field_span="19"
                :label_span="24"
                :translateLabel="false"
                field_mark="required"
                label="Company Name"
                name="company_name"
                placeholder="Jikatel"
                rules="required"></dg-text-field>
          </el-col>
          <el-col :class="$langConfig.cssClass" :span="(!editMode)? 6 : 6">
            <dg-text-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :defaultValue="12"
                :field_span="19"
                :label_span="24"
                :translateLabel="false"
                field_mark="optional"
                label="Skype"
                name="skype_account"
                placeholder="Jikatel"
                rules=""></dg-text-field>
          </el-col>
        </el-row>
        <el-row>
          <el-col :class="$langConfig.cssClass" :span="(!editMode)? 6 : 6">
            <dg-select-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"

                :field_span="19"
                :label_span="24"
                valueProperty="country"
                labelProperty="country"
                :options="countriesList"
                :translateLabels="false"
                :translateLabel="false"
                field_mark="required"
                label="Country"
                name="country"
                rules="required"></dg-select-field>
          </el-col>
          <el-col :class="$langConfig.cssClass" :span="(!editMode)? 6 : 6">
            <dg-select-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"

                :field_span="19"
                :label_span="24"
                valueProperty="country"
                labelProperty="country"
                :options="subscribePlansList"
                :translateLabels="false"
                :translateLabel="false"
                field_mark="required"
                label="Subscribe Plan"
                name="subscribe_plan_id"
                rules="required"></dg-select-field>
          </el-col>
        </el-row>
      </div>

      <el-row>
        <el-col :span="24" class="mt-5 border-top" v-loading="loadingRequests">
          <el-col :class="$langConfig.cssClass" :span="20" class="mt-4">
            <dg-label-field class="error-field-validate-message" label="fields_do_not_match_the_required_criteria"
                            v-show="!this.validData"></dg-label-field>
          </el-col>
          <el-col :span="4" class="pt-3 text-right">
            <dg-button
                :click="createModule"
                :text="actionButtonLabel"
                :type="actionButtonType"
                icon="el-icon-plus mr-2"></dg-button>
          </el-col>
        </el-col>
      </el-row>
    </div>
    <div v-else>
      <h5 class="text-success" v-if="profileChanged">
        Profile Updated <i class="el-icon-circle-check text-success"></i> <br>
        <small>You have to logout and login to see changes</small>
      </h5>
    </div>
  </div>
</template>

<script>


import fieldController from 'devegram-vue-data-collecter-eui/src/mixins/fieldController';
import module from './../index.js'
import iziToast from 'izitoast'

export default {
  mixins: [fieldController],
  props: {
    formTitle: {
      required: false,
    },
    isForCurrentActiveUser: {
      required: false,
      type: Boolean,
      default: true
    },
    showPasswordField: {
      required: true,
      type: Boolean
    },
    editMode: {
      required: true,
      type: Boolean
    },
    onEditCallback: {
      required: false,
      default: () => {

      }
    },
    onCreateCallback: {
      required: false
    }
  },
  data() {
    return {
      dataModel: {},
      countriesList: [],
      userProfileFirstUpdate: false,
      selectedProfileId: null,
      subscribePlansList: [],
      profileCreated: false,
      profileChanged: false,
      control: this,
      amReady: true,
      isSelectedUserReseller: false,
      isSelectedUserCustomer: false,
      isSelectedUserMrt: false,
      module: {
        ...module
      },
      profilesOptionsList: $store.state['App-User-state'].usersProfiles
    }
  },
  mounted() {
    if (this.dataToBind) {
      this.selectedProfileId = this.dataToBind.user_profile_id
      this.isSelectedUserReseller = $store.getters['App-User-state/isProfileIdForReseller'](this.selectedProfileId)
      this.isSelectedUserCustomer = $store.getters['App-User-state/isProfileIdForCustomer'](this.selectedProfileId)
      this.isSelectedUserMrt = $store.getters['App-User-state/isProfileIdForMrt'](this.selectedProfileId)

      if (this.isSelectedUserReseller) {
        this.bindResellerFields()
      } else if (this.isSelectedUserCustomer) {
        this.bindCustomerFields()
      }

      if (this.isSelectedUserCustomer) {
        this.userHasModuleApiCall({id: this.dataToBind.id, module: 'voip-lcr-menu'}).then((r) => {
          $vue.set(this.dataModel, 'customerLCRFeature', r === 1)
        })
      }

    } else {
      this.fields['user_profile_id'].field.field_value = '4'
      this.fields['user_profile_id'].field.updateValue()
      this.getSubscriptionPlansList().then(r => {
        console.log('this.subscribePlansList = r', r)
        this.subscribePlansList = r
      })
    }
  },
  computed: {
    ...$mapState('ajaxManager', ['loadingRequests']),
    ...$mapState('datacloud-entity-category-state', ['categoriesSuggestion']),
    ...$mapState('datacloud-entity-state', ['openedEntity']),
    actionButtonLabel: function () {
      if (!this.editMode) {
        return 'create';
      } else {
        return 'edit'
      }
    },
    actionButtonType: function () {
      if (!this.validData) {
        return 'danger';
      } else {
        return 'primary app-bg-primary'
      }
    }
  },
  methods: {
    updateCustomerLCRFeature() {
      let data = {
        user_id: this.dataToBind.id,
        modules: [
          'voip-lcr-menu',
          'voip-lcr-top-destinations'
        ]
      }
      if (this.dataModel.customerLCRFeature) {
        this.giveUserModules({data})
      } else {
        this.removeUserModules({data})
      }
    },
    bindCustomerFields () {
      this.getCustomerFields({id: this.dataToBind.id}).then((r) => {
        $vue.set(this.dataModel, 'usingPrivateRate', r.using_private_rate === 1)
      })
    },
    bindResellerFields() {
      this.getResellerFields({id: this.dataToBind.id}).then((r) => {
        this.fields['vos_link'].field.field_value = r.data.vos_link
        this.fields['vos_link'].field.updateValue()

        this.fields['system_profit_percent'].field.field_value = r.data.system_profit_percent
        this.fields['system_profit_percent'].field.updateValue()
      })
    },
    profileUpdated(inputField) {
      delete this.dataModel['customerLCRFeature']
      let value = inputField.field.field_value
      if (value) {
        this.selectedProfileId = value
      }

      this.isSelectedUserReseller = $store.getters['App-User-state/isProfileIdForReseller'](this.selectedProfileId)
      this.isSelectedUserCustomer = $store.getters['App-User-state/isProfileIdForCustomer'](this.selectedProfileId)
      this.isSelectedUserMrt = $store.getters['App-User-state/isProfileIdForMrt'](this.selectedProfileId)

      if (!this.userProfileFirstUpdate) {
        this.userProfileFirstUpdate = true
        if (this.dataToBind) {
          this.selectedProfileId = this.dataToBind.user_profile_id
          this.fields['user_profile_id'].field.field_value = this.selectedProfileId
          this.fields['user_profile_id'].field.updateValue()
          if (this.isSelectedUserReseller) {
            this.bindResellerFields()
          } else if (this.isSelectedUserCustomer) {
            this.bindCustomerFields()
          }
        }
      }
    },
    getFormTitle() {
      if (this.formTitle) {
        return this.formTitle
      } else {
        return ((!this.editMode) ? this.$ml.get('create') : this.$ml.get('edit')) + ' ' + this.module.info.caption
      }
    },
    ...$mapActions('user-mrt-features-api', {
      prepareNewMrtFields: 'create',
    }),
    ...$mapActions('user-customer-features-api', {
      prepareNewCustomerFields: 'create',
      getCustomerFields: 'get',
      updateCustomerFields: 'update'
    }),
    ...$mapActions('user-reseller-features-api', {
      prepareNewResellerFields: 'create',
      getResellerFields: 'get',
      updateResellerFields: 'update'
    }),
    ...$mapActions('user-api', {
      userHasModuleApiCall: 'userHasModule',
      giveUserModules: 'giveUserModules',
      removeUserModules: 'removeUserModules',
      getSubscriptionPlansList: 'getSubscriptionPlansList'
    }),
    validateResellerUserCreation() {
      let f1 = this.standaloneFieldValidate(this.fields['vos_link'], 'required')
      let f2 = this.standaloneFieldValidate(this.fields['system_profit_percent'], 'required')

      if (!f1 || !f2) {
        return false
      }
      return true
    },
    createModule() {
      this.validateFields().then(result => {
        if (this.selectedProfileId == 5) {
          if (!this.validateResellerUserCreation()) {
            return
          }
        }

        let context = this;
        let data = {data: this.dataModel};

        if (this.editMode) {
          delete this.dataModel.username
        }

        if (this.editMode) {
          data.id = this.dataToBind.id;
        }

        if (!result) return

        let api = null
        if (this.isForCurrentActiveUser) {
          api = (this.editMode) ? 'updateLoggedInProfile' : 'create';
        } else {
          api = (this.editMode) ? 'update' : 'create';
        }

        this.$store.dispatch(this.module.info.name + '-api/' + api, data).then(response => {
          this.selectedProfileId = 0

          let createdUserId = response.data.id

          if (this.isSelectedUserReseller) {
            let data = {
              system_profit_percent: this.dataModel.system_profit_percent,
              vos_link: this.dataModel.vos_link,
            }
            if (api == 'create') {
              data.user_id = createdUserId
              this.prepareNewResellerFields({data})
            } else {
              data.user_id = this.dataToBind.id
              this.updateResellerFields({data})
            }
          }

          if (this.isSelectedUserCustomer) {
            let data = {
              using_private_rate: (this.dataModel.usingPrivateRate == true)? 1 : 0
            }
            if (api == 'create') {
              data.user_id = createdUserId
              this.prepareNewCustomerFields({data})
            } else {
              data.user_id = this.dataToBind.id
              this.updateCustomerLCRFeature({data})
              this.updateCustomerFields({data})
            }
          }


          if (this.isSelectedUserMrt) {
            let data = {
              user_id: createdUserId
            }
            if (api == 'create') {
              data.user_id = createdUserId
              this.prepareNewMrtFields({data})
            }
          }


          iziToast.success({
            title: this.$ml.get('successfully'),
            message: '',
            position: 'topRight',
            close: false,
            timeout: 1900,
            animateInside: true
          });

          if (this.editMode) {
            this.profileChanged = true
          } else {
            this.profileCreated = true
          }


          if (context.editMode && context.onEditCallback) {
            context.onEditCallback(response.data)
          } else {
            this.amReady = false
            $vue.nextTick(() => {
              context.amReady = true
              if (context.onCreateCallback) {
                context.onCreateCallback(response.data)
              }
            })
          }
        }).catch(error => {
          iziToast.warning({
            title: 'Oops ... failed',
            message: '',
            position: 'topRight',
            close: false,
            timeout: 1900,
            animateInside: true
          });
          console.log('catched error', error)
          this.setfieldsErrorMessage({
            fieldsErrors: error.response.data
          }, this.arefieldsValid);
        })

      })
    }
  }
}
</script>
